
body,div,span,header,footer,nav,section,aside,article,ul,dl,dt,dd,li,a,p,h1,h2,h3,h4,h5,h6,i,b,textarea,button,input,select,figure,figcaption{padding:0;margin:0;list-style:none;font-style:normal;text-decoration:none;border:none;font-family:'Avenir', Helvetica, Arial, sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background:none;-webkit-tap-highlight-color:transparent
}
body:focus,div:focus,span:focus,header:focus,footer:focus,nav:focus,section:focus,aside:focus,article:focus,ul:focus,dl:focus,dt:focus,dd:focus,li:focus,a:focus,p:focus,h1:focus,h2:focus,h3:focus,h4:focus,h5:focus,h6:focus,i:focus,b:focus,textarea:focus,button:focus,input:focus,select:focus,figure:focus,figcaption:focus{outline:none
}
body{font-size:14px;color:#4A4A4A
}
a,img{vertical-align:top
}
::-webkit-scrollbar{width:2px;height:0px;border:0;background-color:#fff
}
::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 1px rgba(238,234,234,0.89);border-radius:10px;background-color:#fff
}
::-webkit-scrollbar-thumb{border-radius:10px;-webkit-box-shadow:inset 0 0 6px rgba(238,234,234,0.89);background-color:#eee
}
#app{padding-top:1.02rem;background-color:#f2f2f2
}
#app section{padding:.2rem;background-color:#FFF
}
#app .list-box{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-ms-flex-wrap:wrap;flex-wrap:wrap
}
#app .tag-list{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-ms-flex-wrap:wrap;flex-wrap:wrap
}

.head[data-v-10598150]{-webkit-box-sizing:border-box;box-sizing:border-box;position:fixed;top:0;left:0;z-index:9999;width:100%;padding:.2rem;background:#FFF;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center
}
.head #menu[data-v-10598150]{width:.4rem;height:.32rem
}
.head h3[data-v-10598150]{position:relative;left:.4rem;font-size:.48rem;color:#ff5196;font-family:"didot"
}
.head button[data-v-10598150]{width:1.36rem;height:.52rem;border-radius:.3rem;line-height:.52rem;font-size:.24rem;background-color:#FF5196;color:#FFF
}
.head .nav-ul[data-v-10598150]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;position:fixed;top:.9rem;left:0;right:0;width:100%;font-size:.32rem;font-weight:600;line-height:.48rem;text-align:center
}
.head .nav-ul .nav-li[data-v-10598150]{display:inline-block;padding:.2rem;color:#fff
}
.head .nav-list-enter-active[data-v-10598150],.head .nav-list-leave-active[data-v-10598150]{-webkit-transition:all 300ms ease-in-out;transition:all 300ms ease-in-out
}
.head .nav-list-enter[data-v-10598150],.head .nav-list-leave-to[data-v-10598150]{-webkit-transform:translateY(30px);transform:translateY(30px);opacity:0
}
.head .nav-list-enter-active[data-v-10598150]:nth-child(1n+2){-webkit-transition-delay:100ms;transition-delay:100ms
}
.head .nav-list-enter-active[data-v-10598150]:nth-child(1n+3){-webkit-transition-delay:200ms;transition-delay:200ms
}
.head .nav-list-enter-active[data-v-10598150]:nth-child(1n+4){-webkit-transition-delay:300ms;transition-delay:300ms
}
.head .nav-list-enter-active[data-v-10598150]:nth-child(1n+5){-webkit-transition-delay:400ms;transition-delay:400ms
}
.head .nav-list-enter-active[data-v-10598150]:nth-child(1n+6){-webkit-transition-delay:500ms;transition-delay:500ms
}
.head .nav-active[data-v-10598150]{-webkit-transition:transfrom 5s ease-in;transition:transfrom 5s ease-in;-webkit-transform:rotate(90deg);transform:rotate(90deg)
}
.head-down-spread[data-v-10598150]{-webkit-box-sizing:border-box;box-sizing:border-box;position:absolute;top:0;left:0;-webkit-transform:translateY(1rem);transform:translateY(1rem);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;padding:.4rem .2rem;text-align:center;background-color:#fff
}
.head-down-spread hr[data-v-10598150]{width:2px;height:1.2rem;margin:0 .6rem;background-color:#ccc;border:none
}
.head-down-spread h5[data-v-10598150]{padding:.2rem 0 .1rem;color:#444
}
.head-down-spread p[data-v-10598150]{font-size:.24rem;color:#777
}

.foot-box[data-v-6c8f7d6b]{margin-top:0 !important
}
.foot-link[data-v-6c8f7d6b]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center
}
.foot-link a[data-v-6c8f7d6b]{padding:0 .2rem;border-right:solid 1px #CBCBCB;font-size:.28rem;line-height:.44rem;color:#6A6A6A
}
#record-gongan[data-v-6c8f7d6b]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:.4rem;padding-bottom:.1rem
}
#record-gongan img[data-v-6c8f7d6b]{width:.4rem;margin-right:.1rem
}
#record-gongan>a[data-v-6c8f7d6b],#record[data-v-6c8f7d6b]{text-align:center;line-height:.4rem;font-size:.24rem;color:#939393
}
#record[data-v-6c8f7d6b]{padding-bottom:.4rem
}

.download-box[data-v-41b7f773]{text-align:center;padding:.4rem 0 !important
}
.download-box .download-btn[data-v-41b7f773]{display:inline-block;width:5rem;height:.8rem;line-height:.8rem;border-radius:.06rem;background-color:#ff5196;font-size:.32rem;font-weight:600;color:#FFF;text-align:center;letter-spacing:.5px
}

.content-box[data-v-758d16bc]{overflow-x:hidden
}
.nav-link-box[data-v-758d16bc]{text-align:center
}
#change-group[data-v-758d16bc]{color:#F43F43
}

.banner-slide[data-v-5027c070]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-transition:all 1s ease-in-out;transition:all 1s ease-in-out
}
.banner-slide .banner-img[data-v-5027c070]{width:100%
}

.link-item[data-v-4bb4a578]{position:relative;display:inline-block;min-height:3.2rem
}
.link-item .link-img[data-v-4bb4a578]{width:100%;border-radius:.1rem
}
.link-item .link-title[data-v-4bb4a578]{position:absolute;top:0;left:0;width:100%;height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:.32rem;font-weight:600;color:#FFF
}
.link-item .label[data-v-4bb4a578]{border-bottom:solid 1.2px #FFF
}
.link-item[data-v-4bb4a578]:not(:last-child){margin-bottom:.2rem
}

.gallery-box[data-v-bf10b1b2]{width:3.45rem;border-radius:.1rem;border:.02rem solid #d6d6d6;-webkit-box-shadow:0 0 .08rem #acacac;box-shadow:0 0 .08rem #acacac;margin-bottom:.12rem
}
.gallery-box main[data-v-bf10b1b2]{height:4.36rem;overflow:hidden;border-radius:5px 5px 0 0
}
.gallery-box .model-cover-box[data-v-bf10b1b2]{height:3.46rem
}
.gallery-box .title[data-v-bf10b1b2]{padding:.2rem;font-size:.28rem;line-height:.44rem;font-weight:500;text-align:center;overflow:hidden;text-overflow:ellipsis;white-space:nowrap
}
.gallery-box .name[data-v-bf10b1b2]{display:block;padding:0 10px 5px;margin-top:-.1rem;font-size:.24rem;font-weight:500;line-height:.4rem;color:#4C91E2;text-decoration:underline
}
.gallery-box .tag-box[data-v-bf10b1b2]{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;padding:0 10px
}
.gallery-img[data-v-bf10b1b2]{width:100%
}

.tag[data-v-146c5aba]{margin:0 .1rem .1rem 0;padding:.1rem;border:1px solid #D9D9D9;font-size:.24rem;line-height:.24rem;color:#4C91E2;font-weight:500
}
.tag[data-v-146c5aba]:hover{color:#FF3399
}

h4[data-v-509aeb28]{padding-bottom:.2rem;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:.32rem;line-height:.48rem
}

#pagination[data-v-0c6ada84]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-weight:bold;padding:.2rem .4rem .5rem;background-color:#fff
}
#pagination #total[data-v-0c6ada84]{margin-left:.2rem;font-size:.24rem;color:#595b56;line-height:.4rem
}
#pagination #page[data-v-0c6ada84]{width:1rem;border:solid 1px #d9d9d9;border-radius:3px;height:.5rem;text-align:center;font-size:.32rem
}
#pagi-box[data-v-0c6ada84]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:3px;list-style:none
}
#pagi-box li[data-v-0c6ada84]{padding:.1rem .2rem;border-left:solid 1px #d9d9d9;border-top:solid 1px #d9d9d9;border-bottom:solid 1px #d9d9d9;font-size:.32rem;color:#595b56;cursor:pointer
}
#pagi-box li[data-v-0c6ada84]:last-child{border-right:solid 1px #d9d9d9
}
#pagi-box li[data-v-0c6ada84]:not(.disabled):not(.cur):hover{border:solid 1px #ff3399 !important;color:#ff3399
}
#pagi-box .cur[data-v-0c6ada84]{border:solid 1px #ff3399 !important;background-color:#ff3399;color:#fff
}
#pagi-box .disabled[data-v-0c6ada84]{background-color:#e5e5e5;border:solid 1px #e5e5e5;color:#aaa;cursor:no-drop
}

.content-box[data-v-8c4a211a]{background-color:#FFF
}
img[data-v-8c4a211a]{width:100%
}
.buy-box[data-v-8c4a211a]{padding:.2rem;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center
}
.buy-box .buy-price[data-v-8c4a211a]{color:#f39;font-size:.36rem;font-weight:600
}
.buy-box .buy-btn[data-v-8c4a211a]{display:inline-block;width:1.6rem;height:.6rem;line-height:.6rem;border-radius:3px;background-color:#FF5196;color:#FFF;text-align:center
}
.content[data-v-8c4a211a]{padding:.2rem
}
.content .desc[data-v-8c4a211a]{padding:.2rem 0;text-indent:.56rem
}

.content-box[data-v-e5cfad50]{margin-top:.2rem;background-color:#fff
}
.content-box .title[data-v-e5cfad50]{text-align:center
}
.content-box .article[data-v-e5cfad50]{padding:0 10px
}
.content-box .intro[data-v-e5cfad50]{margin-bottom:.2rem
}
.content-box .photo[data-v-e5cfad50]{width:calc(100% - 20px);margin:auto 0;border-radius:5px
}

.model-info-box[data-v-3e4bb2e8]{margin-bottom:.4rem;padding:.2rem 0;text-align:center;background-color:#f2f2f2
}
.model-info-box .head-img[data-v-3e4bb2e8]{margin:0 auto;width:2.4rem;border-radius:1.2rem
}
.model-info-box h5[data-v-3e4bb2e8]{font-size:.32rem;line-height:.48rem
}

.text-bar-box[data-v-3b7ebc90]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:20px 10px 10px;font-size:.28rem;font-weight:bold
}
.text-bar-box .bar-item[data-v-3b7ebc90]{width:50%;height:22px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap
}
.text-bar-box a[data-v-3b7ebc90]{color:#4C92E2;cursor:pointer
}

.content-box[data-v-2855ba8b]{padding-top:1.16rem
}
.photoLable-box[data-v-2855ba8b]{-webkit-box-sizing:border-box;box-sizing:border-box;position:fixed;top:.9rem;width:100%;margin-top:0 !important
}

.label-box[data-v-648c2603]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;padding:.1rem 0;overflow-x:scroll
}
.label-box .label-item[data-v-648c2603]{-ms-flex-negative:0;flex-shrink:0;width:1.2rem;height:.56rem;margin-bottom:.1rem;border-radius:.4rem;background-color:#F1F0F6;line-height:.56rem;text-align:center
}
.label-box .label-item h3[data-v-648c2603]{color:#4a4a4a;font-size:.24rem
}
.label-box .label-item[data-v-648c2603]:not(:last-child){margin-right:.2rem
}
.label-box .label-item[data-v-648c2603]:hover{border:solid 1px #F39
}
.label-box .label-item:hover h3[data-v-648c2603]{color:#F39
}
.label-box .active[data-v-648c2603]{border:none;background-color:#F39
}
.label-box .active h3[data-v-648c2603]{color:#FFF
}

.model-detail-time[data-v-67490678]{margin-bottom:10px
}
.more-box[data-v-67490678]{width:49%
}
.model-album-img[data-v-67490678]{margin-bottom:10px
}
.model-tag-list[data-v-67490678]{margin-top:10px
}

.more-box[data-v-c505f5ca]{position:relative
}
.more-img[data-v-c505f5ca]{width:100%;-webkit-filter:blur(8px);filter:blur(8px)
}
.more-content[data-v-c505f5ca]{position:absolute;top:0;z-index:2;width:100%;height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#FFF;font-size:16px;font-weight:600;text-align:center
}
.more-content .more-tip[data-v-c505f5ca]{margin-bottom:10px
}
.more-content .more-btn[data-v-c505f5ca]{display:inline-block;width:120px;border:dashed 1px #FFF;padding:5px;color:#FFF
}

.head[data-v-3487d482]{background-color:#FFF
}
.head-img-contain[data-v-3487d482]{height:250px;overflow-y:hidden
}
.head-img-contain img[data-v-3487d482]{opacity:.7
}
.model-info-box[data-v-3487d482]{-webkit-box-sizing:border-box;box-sizing:border-box;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:relative;-webkit-transform:translateY(-50%);transform:translateY(-50%);width:6.9rem;margin:0 auto;padding:10px;border:1px solid #d6d6d6;border-radius:5px;background-color:#fff
}
.model-info-box .head-img[data-v-3487d482]{border-radius:30px;margin-right:10px
}
.model-info-box .model-info[data-v-3487d482]{font-size:12px;color:#ACACAC
}

.content-box[data-v-5ccf09f1]{padding-top:1.16rem
}
.photoLable-box[data-v-5ccf09f1]{-webkit-box-sizing:border-box;box-sizing:border-box;position:fixed;top:.9rem;width:100%;margin-top:0 !important
}

.moment-ul[data-v-24f54772]{list-style:none
}
.moment-li[data-v-24f54772]{margin-bottom:.5rem
}
.moment-li p[data-v-24f54772]{margin-bottom:.2rem
}
.moment-img-box[data-v-24f54772]{-webkit-column-count:2;column-count:2;-webkit-column-gap:.2rem;column-gap:.2rem
}
.moment-img-box .moment-img[data-v-24f54772]{-webkit-column-break-inside:avoid;break-inside:avoid;margin-bottom:.2rem
}
.dynamic-more[data-v-24f54772]{font-size:.24rem !important;color:#aaa !important
}
.list-ul[data-v-24f54772]{margin-top:0 !important
}

.list-ul[data-v-6618ed6e]{background-color:#FFF;margin-top:10px
}
.list-item[data-v-6618ed6e]{height:122px;overflow-y:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;margin-bottom:10px;padding:0 10px 5px;-webkit-box-shadow:1px 1px 2px #f4f4f4;box-shadow:1px 1px 2px #f4f4f4;font-size:14px
}
.list-item .item-img[data-v-6618ed6e]{width:2.4rem;border-radius:1px;margin-right:10px
}
.list-item .item-title[data-v-6618ed6e]{width:4.1rem;font-size:14px;line-height:22px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap
}
.list-item .item-time[data-v-6618ed6e]{font-size:12px;color:#aaa;line-height:20px
}
.list-item[data-v-6618ed6e]:nth-child(1){padding-top:10px
}
.list-item[data-v-6618ed6e]:last-child{margin-bottom:0
}
#pagination[data-v-6618ed6e]{padding-top:20px !important
}

.news-box[data-v-b88e8cda]{padding:10px;background-color:#FFF;line-height:22px
}
.news-box .title[data-v-b88e8cda]{font-size:16px;line-height:24px
}
.news-box .time[data-v-b88e8cda]{color:#777;margin-bottom:10px
}
.link-box[data-v-b88e8cda]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:10px
}
.link-box>a[data-v-b88e8cda]{color:blue
}

.moment-ul[data-v-76be6194]{list-style:none
}
.moment-li[data-v-76be6194]{margin-bottom:25px
}
.moment-li p[data-v-76be6194]{margin-bottom:10px
}
.moment-img-box[data-v-76be6194]{-webkit-column-count:2;column-count:2;-webkit-column-gap:10px;column-gap:10px
}
.moment-img-box .moment-img[data-v-76be6194]{-webkit-column-break-inside:avoid;break-inside:avoid;margin-bottom:10px
}

.list-ul[data-v-fd038c46]{margin-top:0 !important
}

.content-box[data-v-7f7021d0]{margin-top:10px;padding:20px;background-color:#fff;color:#777
}
.content-box .text[data-v-7f7021d0],.content-box .contact[data-v-7f7021d0]{margin-bottom:10px;font-size:14px;line-height:22px
}
.content-box .text[data-v-7f7021d0]{text-indent:28px
}
.content-box h4[data-v-7f7021d0]{font-size:16px;line-height:30px
}
